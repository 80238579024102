import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useOptions } from '../../ajax/api';
import LoadingSpinner from '../shared/LoadingSpinner';

export default () => {

    const { t } = useTranslation();

    const options = useOptions();

    return <div className="container-fluid container-full" role="main">
        <Helmet>
            <title>{t('qualifying.title')}</title>
        </Helmet>
        <div className="row mb-3">
            <div className="col">
                <h1>{t('qualifying.title')}</h1>
            </div>
        </div>
        {!options && <LoadingSpinner />}
        {options && <div className="row mb-5 row-cols-1 row-cols-md-2 row-cols-lg-3" role="list" style={{ fontSize: '1.25em', lineHeight: '1.5em' }}>
            {options.devices.map((r, i) => <div className="col" role="listitem" key={i}>{r.label}</div>)}
        </div>}
    </div>;
}