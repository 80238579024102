import axios from 'axios';
import { API_URL } from '../constants';


const dataOnly = (response) => response.data;

const ieCacheBust = config => {

    if (config.method === 'get') {

        config.params = { ...config.params, t: new Date().getTime() };
    }
    return config;
}

const addCacheBustIfIE = instance => {

    if (navigator.userAgent.includes('Trident')) {

        instance.interceptors.request.use(ieCacheBust);
    }
}

const createInstance = baseURL => {

    const instance = axios.create({
        baseURL
    });

    addCacheBustIfIE(instance);
    instance.interceptors.response.use(dataOnly);

    return instance;
}

export default createInstance(API_URL);

export const rootHandler = createInstance('');