import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { msPrivacyUrl } from '../constants';
import { chPrivacyUrl } from '../constants';

export default () => {

    const { t } = useTranslation();

    return <footer className="bg-light" role="contentinfo">
        <div className="container-fluid">
            <div className="row pb-3">
                <div className="col-12 text-center">
                    <NavLink to="/terms" aria-current="page">{t('site.footer.terms')}</NavLink> | <a href={msPrivacyUrl} target="_blank" rel="noopener noreferrer" aria-label={`${t('site.footer.msPrivacy')} ${t('site.opensNew')}`}>{t('site.footer.msPrivacy')}</a> | <a href={chPrivacyUrl} target="_blank" rel="noopener noreferrer" aria-label={`${t('site.footer.chPrivacy')} ${t('site.opensNew')}`}>{t('site.footer.chPrivacy')}</a>
                    <br />
                    {t('site.footer.reserved')}
                    <br />
                    <span dangerouslySetInnerHTML={{
                        __html: t('site.footer.managed', {
                            epayLink: `<a href="https://epayworldwide.com" target="_blank" aria-label="epayworldwide.com ${t('site.opensNew')}" rel="noopener noreferrer">epayworldwide.com</a>`
                        })
                    }}></span>
                    { }
                </div>
            </div>
        </div>
    </footer>
};