import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './polyfills';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import './i18n';
import LoadingSpinner from './components/shared/LoadingSpinner';

import { makeServer } from './ajax/server';
if (process.env.REACT_APP_STATIC === 'true') {
  makeServer({ environment: process.env.NODE_ENV });
}

ReactDOM.render(
  <Suspense fallback={<LoadingSpinner />}>
    <BrowserRouter>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root')
);




