import React from 'react';
import { useTranslation } from 'react-i18next';

export default () => {

    const { t } = useTranslation();
    return <div className="row">
        <div className="col-12">
            * {t('site.denotesRequired')}
        </div>
    </div>;
}