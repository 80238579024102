import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TIMESTAMP, promo } from '../constants';
import handler from './handler';

export const validateProductKey = (productKey) => handler.post('/validate-product-key', { productKey, promo });

export const validateEmail = (email, claimId) => handler.post('/validate-email', { email, claimId });

export const getOptions = (language) => handler.get(`/options/${promo}/${language}`, { params: { t: TIMESTAMP } });

export const saveTransaction = ({ claimId, country, merchant, receiptLanguage, receiptDate, subscription, qualifyingPurchase }) =>
    handler.post('/transaction', { claimId, country, merchant, receiptLanguage, receiptDate, subscription, qualifyingPurchase });

export const savePurchaseDetail = ({ claimId, email,  country, merchant, receiptLanguage, subscription, qualifyingPurchase }) =>
  handler.post('/purchase-detail', { claimId, email, country, merchant, receiptLanguage, subscription, qualifyingPurchase });

export const uploadReceipt = (formData) => handler.request({
    method: 'POST',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/upload'
});


const emptyOptions = {
    countries: [],
    merchants: [],
    receiptLanguages: [],
    subscriptions: [],
    devices: []
};

let cachedOptions;
let cachedOptionsLanguage;

export const useOptions = () => {

    const { i18n } = useTranslation();
    const [options, setOptions] = useState(i18n.language === cachedOptionsLanguage ? cachedOptions : null);

    useEffect(() => {

        const load = async () => {

            const response = await getOptions(i18n.language);

            if (response.success) cachedOptions = response.data;
            else cachedOptions = emptyOptions;

            // 10445-10484 are Retailer Is Not Listed, move to end
            cachedOptions.merchants = cachedOptions.merchants.filter(r => parseInt(r.value) < 10445 || parseInt(r.value) > 10484)
                .concat(cachedOptions.merchants.filter(r => !(parseInt(r.value) < 10445 || parseInt(r.value) > 10484)));

            // sort en to the end
            cachedOptions.receiptLanguages = cachedOptions.receiptLanguages.filter(r => r.value !== 'en-US')
                .concat(cachedOptions.receiptLanguages.filter(r => r.value === 'en-US'));

            cachedOptionsLanguage = i18n.language;
            setOptions(cachedOptions);
        }

        if (!options) load();
    }, [options, i18n.language]);

    return options;
}
