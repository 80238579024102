import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from "yup";

import { CheckboxField } from '../shared/FormElements';
import { useTranslation } from 'react-i18next';
import { TermsPanel } from '../terms/TermsPage';
import { FocusOnFirstError } from '../../utils';
import DenotesRequiredRow from '../shared/DenotesRequiredRow';

const InnerForm = ({ values, setStatus, isSubmitting }) => {

    const { t } = useTranslation();

    return <Form noValidate autoComplete="off">
        <FocusOnFirstError />
        <div className="row">
            <div className="col-12 overflow-auto" style={{ maxHeight: '150px' }}>
                <h4 className="text-center">{t('claim.terms.heading')}</h4>
                <TermsPanel />
            </div>
        </div>

        <div className="row">
            <div className="col-12">
                <hr />
                <Field name="agree"
                    label={t('claim.terms.agree') + ' *'}
                    checked={values.agree}
                    component={CheckboxField}
                    errorName={t('claim.terms.agree')}
                    aria-required
                />
            </div>
        </div>

        <div className="row">
            <div className="col-12 text-right">
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                    onClick={() => setStatus('submitting')}>{t('site.claim')}</button>
            </div>
        </div>

        <DenotesRequiredRow />
    </Form>
};

export default ({ onSuccess }) => {

    const { t } = useTranslation();
    return <Formik
        enableReinitialize
        initialValues={{
            agree: false
        }}
        validationSchema={Yup.object().shape({
            agree: Yup.boolean().test('is-true', () => t('validation.termsRequired'), val => val)
        })}
        onSubmit={async (values, { setSubmitting }) => {
            try {
                setSubmitting(false);
                onSuccess(values);
            } finally {
            }
        }}
    >
        {(formProps) => <InnerForm {...formProps} />}
    </Formik>;
}
