import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const iframeStyles = { height: '100%', width: '100%', border: 'none' };

export default () => {

    const { t, i18n } = useTranslation();

    var lang_override = i18n.language;
    switch (lang_override) {
        case "zh-CN":
            lang_override = "zh";
            break;
        case "zh-HK":
            lang_override = "zhhk";
            break;
        case "zh-TW":
            lang_override = "zhtw";
            break;
        case "en-HK":
            lang_override = "enhk";
            break;
        default:
            lang_override = i18n.language;
            break;
    }
    const overrideCode = lang_override;

    //const iframeParams = useMemo(() => `/lang_code/${i18n.language}/offset/${-1 * (new Date().getTimezoneOffset())}`, [i18n.language]);
    const iframeParams = useMemo(() => `/lang_code/${overrideCode}/offset/${-1 * (new Date().getTimezoneOffset())}`, [overrideCode]);

    return <div className="container-fluid" role="main">
        <Helmet>
            <title>{t('support.title')}</title>
        </Helmet>
        <div className="row mb-1">
            <div className="col">
                <h1>{t('support.title')}</h1>
            </div>
        </div>

        <div className="row mb-5">
            <div className="col-12 col-lg-6 chat-container">
                <iframe
                    style={iframeStyles}
                    //scrolling="no"
                    src={`https://ms365.custhelp.com/app/chat/chat_launch_microsoft${iframeParams}`}
                    title={t('support.chat.heading')}
                ></iframe>
            </div>
            <div className="col-12 col-lg-6 ask-container">
                <iframe
                    style={iframeStyles}
                    src={`https://ms365.custhelp.com/app/ask_microsoft${iframeParams}`}
                    title={t('support.email.heading')}></iframe>
            </div>
        </div>
    </div>;
}