import React, { useCallback } from 'react';
import { Field, getIn } from 'formik';
import { Typeahead } from 'react-bootstrap-typeahead';

import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { formatISO, parseISO } from 'date-fns';

import { useTranslation } from 'react-i18next';

const getValidationState = (touched, error, status) =>
    showError(touched, error, status) ? 'is-invalid' : undefined;

const showError = (touched, error, status) =>
    (touched && error) || (status === 'submitted' && error);

const getErrorAriaLabel = (t, label, error) =>
    label && error && t('validation.errorAriaLabel', { label, error });

const getErrorAriaLabelCheckbox = (t, label, error) =>
    label && error && t('validation.errorAriaLabelCheckbox', { label, error });

const FormFeedback = ({ className, children, id, errorAriaLabel }) => <div role="alert" id={id} className={classNames('invalid-feedback', className)} aria-label={errorAriaLabel}>{children}</div>;

const FormGroup = ({ className, children }) => <div className={classNames('form-group', className)}>{children}</div>;

export const TextField = ({ field, form: { touched, errors, status }, className, errorName, ...props }) => {

    const { t } = useTranslation();
    const isTouched = getIn(touched, field.name)
        , error = getIn(errors, field.name)
        , errorId = `${field.name}-error`
        , errorAriaLabel = getErrorAriaLabel(t, errorName, error);

    return <>
        <input
            type="text"
            {...field}
            {...props}
            className={classNames('form-control', className, getValidationState(isTouched, error, status))}
            aria-describedby={errorId}
            id={field.name}

        />
        {showError(isTouched, error, status) &&
            <FormFeedback id={errorId} errorAriaLabel={errorAriaLabel}>{error}</FormFeedback>}
    </>;
}

export const SelectField = ({ field, form: { touched, errors, status }, errorName, children, className, ...props }) => {

    const { t } = useTranslation();
    const isTouched = getIn(touched, field.name)
        , error = getIn(errors, field.name)
        , errorId = `${field.name}-error`
        , errorAriaLabel = getErrorAriaLabel(t, errorName, error);

    return (
        <>
            <select
                {...field}
                {...props}
                className={classNames('custom-select', className, getValidationState(isTouched, error, status))}
                aria-describedby={errorId}
                id={field.name}
            >
                {children}
            </select>
            {showError(isTouched, error, status) &&
                <FormFeedback id={errorId} errorAriaLabel={errorAriaLabel}>{error}</FormFeedback>}
        </>
    );
}

export const SelectTypeahead = ({ field, form: { touched, errors, status }, errorName, items, className, ...props }) => {
  const { t } = useTranslation();
  const isTouched = getIn(touched, field.name)
    , error = getIn(errors, field.name)
    , errorId = `${field.name}-error`
    , errorAriaLabel = getErrorAriaLabel(t, errorName, error);
  const {forwardedRef, ...rest } = props;

  return (
    <>
      <Typeahead
        {...rest}
        className={classNames(className, getValidationState(isTouched, error, status))}
        aria-describedby={errorId}
        id={field.name}
        options={items}
        size='lg'
        flip
        minLength={2}
        newSelectionPrefix={null}
        ref={forwardedRef}
      >
      </Typeahead>
      {showError(isTouched, error, status) &&
      <FormFeedback id={errorId} errorAriaLabel={errorAriaLabel}>{error}</FormFeedback>}
    </>


  );
}

export const CheckboxField = ({ field, form: { touched, errors, status }, label, className, labelClassName, labelStyle, wrapperClassName, wrapperStyle, errorName, ...props }) => {

    const { t } = useTranslation();
    const isTouched = getIn(touched, field.name)
        , error = getIn(errors, field.name)
        , hasError = showError(isTouched, error, status)
        , errorId = `${field.name}-error`
        , errorAriaLabel = getErrorAriaLabelCheckbox(t, errorName, error);

    return (
        <div className={classNames('form-check', wrapperClassName)} style={wrapperStyle} >
            <input type="checkbox" id={field.name} {...field} {...props} className={classNames('form-check-input', className, { 'is-invalid': hasError })} aria-describedby={errorId} />
            <label className={classNames('form-check-label', labelClassName)} style={labelStyle} htmlFor={field.name}>{label}</label>
            {hasError && <FormFeedback id={errorId} errorAriaLabel={errorAriaLabel}>{error}</FormFeedback>}
        </div>
    );
}

const toDate = (val) => val ? parseISO(val) : '';
const fromDate = (val) => val ? formatISO(val, { representation: 'date' }) : '';

export const DatePickerField = ({ field, form: { touched, errors, status }, wrapperClassName, className, placeholder, ...props }) => {

    const { name: fieldName, onChange } = field;

    const isTouched = getIn(touched, fieldName)
        , error = getIn(errors, fieldName)
        , errorId = `${fieldName}-error`;

    const handleChange = useCallback((value, event) => {

        event.target.name = fieldName;
        event.target.value = fromDate(value);
        onChange(event);
    }, [fieldName, onChange]);

    return (
        <>
            <DatePicker
                type="text"
                name={fieldName}
                className={classNames('form-control', className, getValidationState(isTouched, error, status))}
                wrapperClassName={wrapperClassName}
                selected={toDate(field.value)}
                onChange={handleChange}
                onBlur={field.onBlur}
                placeholderText={placeholder}
                strictParsing
                dateFormat="P"
                customInput={<input type="text" aria-describedby={errorId} />}
                {...props}
                id={fieldName}
            />

            {showError(isTouched, error, status) &&
                <FormFeedback className="d-block" id={errorId}>{error}</FormFeedback>}
        </>
    );
}

export const FieldGroup = ({ name, label, tooltip, formGroupClassName, labelClassName, errorName, children, ...props }) => (
    <FormGroup className={formGroupClassName}>
        {label && <label htmlFor={name} className={labelClassName}>{label}</label>}
        <Field name={name} id={name} errorName={errorName || label} {...props}>
            {children}
        </Field>
    </FormGroup>
);
