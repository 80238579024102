import { formatISO, parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../i18n';


export default ({ onFinished, claimId, hostTime }) => {

    const { t } = useTranslation();
    const ts = useMemo(() => hostTime || formatISO(new Date()), [hostTime]);

    return <>
        <div className="row">
            <div className="col alert alert-success ml-3 mr-2" id="final-step-success" role="alert" aria-live="assertive">
                <h4>{t('claim.final.transactionSuccess')}</h4>
                <strong>{t('claim.final.hostTime')}: </strong>{formatDate(parseISO(ts), 'PPpp')}
                <br /><strong>{t('claim.final.case')}: </strong>{claimId}
                <br /><br />{t('claim.final.success')}
            </div>
        </div>

        <div className="row">
            <div className="col-12 text-right">
                <button
                    type="buttpn"
                    className="btn btn-primary"
                    onClick={onFinished}>{t('site.done')}</button>
            </div>
        </div>
    </>;
}