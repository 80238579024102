import React, { useCallback, useState } from 'react';
import { Form, Field, Formik } from 'formik';
import * as Yup from "yup";

import logo from '../../img/ms-logo.png';
import { TextField } from '../shared/FormElements';

import { validateProductKey } from '../../ajax/api';
import { useTranslation } from 'react-i18next';
import { FocusOnFirstError } from '../../utils';
import DenotesRequiredRow from '../shared/DenotesRequiredRow';

const onKeyUpTabNext = (e) => {

    if (e.target.value.length === 5) {
        const form = e.target.form;
        const idx = Array.prototype.indexOf.call(form, e.target);
        form.elements[idx + 1].focus();
        e.preventDefault();
    }
};

const ProductKeySegment = ({ idx, t, ...rest }) => <div style={{ width: '80px' }} className="mr-2 mt-2" >
    <Field name={`segment${idx}`} component={TextField} maxLength="5" aria-label={t('claim.product.aria', { segment: idx }) + ' *'} errorName={t('claim.product.aria', { segment: idx })} aria-required {...rest}/>
</div>;

const InnerForm = ({ isSubmitting, setStatus, setFieldValue, error, disabled }) => {

    const { t } = useTranslation();

    const changeToUpperCase = useCallback((e) => {

        let value = e.target.value || '';
        value = value.toUpperCase().trim();
        setFieldValue(e.target.name, value);
    }, [setFieldValue]);

    return <Form noValidate autoComplete="off">
        <FocusOnFirstError />
        <div className="row">
            <div className="col">
                <label>{t('claim.product.label')}</label>
            </div>
            <div className="col text-right pl-0">
                <button type="button" autoFocus className="btn p-o btn-fivebyfive" data-toggle="modal" data-target="#helpModal">{t('claim.product.where')}<i className="fa fa-info-circle ml-1"></i></button>
            </div>

        </div>
        <div className="row">
            <div className="col-12 col-md-8 col-lg-8 mx-auto mb-3">
                <div className="card">
                    <div className="card-body bg-light">
                        <div className="row mb-2">
                            <div className="col">
                                <img src={logo} alt={t('claim.product.logo.alt')} className="logo" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center flex-wrap flex-md-nowrap">
                            <ProductKeySegment idx={1} t={t} onChange={changeToUpperCase} onKeyUp={onKeyUpTabNext} disabled={disabled} />
                            <ProductKeySegment idx={2} t={t} onChange={changeToUpperCase} onKeyUp={onKeyUpTabNext} disabled={disabled} />
                            <ProductKeySegment idx={3} t={t} onChange={changeToUpperCase} onKeyUp={onKeyUpTabNext} disabled={disabled} />
                            <ProductKeySegment idx={4} t={t} onChange={changeToUpperCase} onKeyUp={onKeyUpTabNext} disabled={disabled} />
                            <ProductKeySegment idx={5} t={t} onChange={changeToUpperCase} onKeyUp={onKeyUpTabNext} disabled={disabled} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {error && <div className="row no-gutters">
            <div className="col-12">
                <div className="alert alert-danger" id="product-step-error" role="alert">
                    {error}
                </div>
            </div>
        </div>}
        {!disabled && <>
            <div className="row">
                <div className="col-12 text-right">
                    <button
                        type="button"
                        className="btn btn-danger mr-2"
                        disabled={isSubmitting}
                        data-toggle="modal"
                        data-target="#cancelModal">{t('site.cancel')}</button>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                        onClick={() => setStatus('submitting')}>{t('site.continue')}</button>
                </div>
            </div>
            <DenotesRequiredRow />
        </>}
    </Form>
};

const segmentRegex = /^[a-zA-Z0-9]*$/;
export default ({ onSuccess, disabled }) => {

    const [error, setError] = useState();
    const { t } = useTranslation();

    return <>
        <Formik
            enableReinitialize
            initialValues={{
                segment1: '',
                segment2: '',
                segment3: '',
                segment4: '',
                segment5: ''
            }}
            validationSchema={Yup.object().shape({
                segment1: Yup.string().trim().required().matches(segmentRegex, t('validation.alphanumeric')).length(5),
                segment2: Yup.string().trim().required().matches(segmentRegex, t('validation.alphanumeric')).length(5),
                segment3: Yup.string().trim().required().matches(segmentRegex, t('validation.alphanumeric')).length(5),
                segment4: Yup.string().trim().required().matches(segmentRegex, t('validation.alphanumeric')).length(5),
                segment5: Yup.string().trim().required().matches(segmentRegex, t('validation.alphanumeric')).length(5)
            })}
            onSubmit={async (values, { setSubmitting }) => {
                try {

                    const pk = [values.segment1, values.segment2, values.segment3, values.segment4, values.segment5].join('-');
                    const result = await validateProductKey(pk, values.recaptcha);
                    if (result.success) {
                        onSuccess({ pk, ...result.data });
                        setError();
                    } else {
                        setError(t([`errors.${result.error}`, 'errors.unspecified']));
                    }
                } catch {
                    setError(t([`errors.500`, 'errors.unspecified']));
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {(formProps) => <InnerForm {...formProps} error={error} disabled={disabled} />}
        </Formik>
    </>;
};
