import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router';
import SupportPage from './components/support/SupportPage';
import Header from './components/Header';
import ClaimPage from './components/claim/ClaimPage';
import LocalesPage from './components/locales/LocalesPage';
import Footer from './components/Footer';
import TermsPage from './components/terms/TermsPage';
import HowItWorksPage from './components/how/HowItWorksPage';
import QualifyingProductsPage from './components/qualifying/QualifyingProductsPage';


export default () => {

    const { t, i18n } = useTranslation();

    return <>
        <Helmet
            titleTemplate={`%s - ${t('site.title')}`}
            defaultTitle={t('site.title')}
        >
            <html lang={i18n.language} />
        </Helmet>

        <Header />

        <Switch>
            <Route exact path="/">
                <ClaimPage />
            </Route>

            <Route path="/support">
                <SupportPage />
            </Route>

            <Route path="/terms">
                <TermsPage />
            </Route>

            <Route path="/locales">
                <LocalesPage />
            </Route>

            <Route path="/qualifying-products">
                <QualifyingProductsPage />
            </Route>

            <Route path="/how-it-works">
                <HowItWorksPage />
            </Route>

            <Redirect to="/" />
        </Switch>

        <Footer />
    </>
};
