import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Animated } from 'react-animated-css';
import { useOptions } from '../../ajax/api';

import TermsStep from './TermsStep';
import ProductStep from './ProductStep';
import FinalStep from './FinalStep';

import logo from '../../img/ms-logo.png';
import banner from '../../img/Lady_600x764.png';
import word from '../../img/Word_40x40.svg';
import excel from '../../img/Excel_40x40.svg';
import powerpoint from '../../img/PowerPoint_40x40.svg';
import outlook from '../../img/Outlook_40x40.svg';
import onedrive from '../../img/OneDrive_40x40.svg';
import PurchaseDetail from './PurchaseDetail';

const ClaimCard = ({ step, currentStep, title, children }) => <div className="card claim-card mb-1">
    {title && <div className={classNames('card-header', { 'active': currentStep === step })} role="heading" aria-level="2">
        {title}
    </div>}
    {currentStep === step && <div className="card-body">
        {children}
    </div>}
</div>;

const STEPS = {
    TERMS: 1,
    PRODUCT: 2,
    DETAILS: 3,
    FINAL: 4
};

const initialStep = STEPS.TERMS;
const initialClaim = {};

export default () => {

    const { t } = useTranslation();
    const [step, setStep] = useState(initialStep);
    const [claim, setClaim] = useState(initialClaim);
    const [hideBanner, setHideBanner] = useState(false);
    const [hideSubscription, setHideSubscription] = useState(false);
    const [productStepDisabled, setProductStepDisabled] = useState(true);
    const [purchaseDetailDisabled, setPurchaseDetailDisabled] = useState(true);

    const nextStep = useCallback((values) => {

        if (values) {
            setClaim(current => ({ ...current, ...values }));
            if(values.subscription) {
                setHideSubscription(true);
            }
        }
        setStep(current => current + 1);

    }, []);

    const reset = useCallback(() => {
        setClaim(initialClaim);
        setStep(initialStep);
        setHideBanner(false);
        setHideSubscription(false);
        setProductStepDisabled(true);
        setPurchaseDetailDisabled(true);
    }, []);

    useEffect(() => {
        const handleShown = (e) => window.jQuery(e.target).find('.close').focus();
        window.jQuery('.modal').on('shown.bs.modal', handleShown);
        return () => window.jQuery('.modal').off('shown.bs.modal', handleShown);
    }, []);

    useEffect(() => {
        if(step === STEPS.PRODUCT){
            setProductStepDisabled(undefined);
            setTimeout(() => {
                setHideBanner(true);
            }, 401);
        } else if (step === STEPS.DETAILS) {
            setProductStepDisabled(true);
            setPurchaseDetailDisabled(undefined);
        }
    }, [step]);

    const options = useOptions();

    return <>
        <div className="container-fluid">

            <Helmet>
                <title>{t('claim.title')}</title>
            </Helmet>

            <Animated animationOut="slideOutUp" animationInDuration={400} isVisible={step === STEPS.TERMS} >
                <div className={classNames('row mb-1 banner-header',{ 'd-none': hideBanner })} role="banner">
                    <div className="col">
                        <img className="banner"
                             src={banner}
                             alt={t('claim.banner.alt')}/>

                        <div className="header d-flex flex-column justify-content-center px-2 px-lg-5">
                            <img className="logo mb-4" src={logo} alt="Microsoft logo"/>
                            <h1>{t('claim.banner.heading')}</h1>
                            <h2 className="d-none d-lg-block my-lg-3">{t('claim.banner.eligible')}</h2>
                            <h3 className="d-lg-none mt-2">{t('claim.banner.eligible')}</h3>
                            <div>
                                <img className="icon" src={word} alt="Microsoft Word"/>
                                <img className="icon" src={excel} alt="Microsoft Excel"/>
                                <img className="icon" src={powerpoint} alt="Microsoft PowerPoint"/>
                                <img className="icon" src={outlook} alt="Microsoft Outlook"/>
                                <img className="icon" src={onedrive} alt="Microsoft OneDrive"/>
                            </div>
                            <div className="text-right offer">{t('claim.banner.offer')}</div>
                        </div>
                    </div>
                </div>
            </Animated>

            <div role="main">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-1">
                        <li className={classNames(
                          'breadcrumb-item',
                          { 'active text-primary': STEPS.TERMS === step },
                          { 'text-secondary': STEPS.TERMS !== step }
                        )}
                            aria-current={STEPS.TERMS === step ? 'page' : undefined}
                        >{t('claim.terms.title')}</li>
                        <li className={classNames(
                          'breadcrumb-item',
                          { 'active text-primary': (STEPS.PRODUCT === step || STEPS.DETAILS === step) },
                          { 'text-secondary': !(STEPS.PRODUCT === step || STEPS.DETAILS === step) }
                        )}
                            aria-current={STEPS.DETAILS === step ? 'page' : undefined}
                        >{t('claim.detail.title')}</li>
                        <li className={classNames(
                          'breadcrumb-item',
                          { 'active text-primary': STEPS.FINAL === step },
                          { 'text-secondary': STEPS.FINAL !== step }
                        )}
                            aria-current={STEPS.FINAL === step ? 'page' : undefined}
                        >{t('claim.final.title')}</li>
                    </ol>
                </nav>

                {step === STEPS.TERMS &&
                <ClaimCard
                    currentStep={step}
                    step={STEPS.TERMS}
                >
                    <TermsStep onSuccess={nextStep} />
                </ClaimCard>}

                {(STEPS.PRODUCT === step || STEPS.DETAILS === step) &&
                <>
                    <ClaimCard>
                        <ProductStep onSuccess={nextStep} disabled={productStepDisabled}/>
                    </ClaimCard>

                    <ClaimCard>
                        <PurchaseDetail onSuccess={nextStep} options={options} claim={claim} hideSubscription={hideSubscription} disabled={purchaseDetailDisabled} />
                    </ClaimCard>
                </>}

                {step === STEPS.FINAL &&
                <ClaimCard
                    currentStep={step}
                    step={STEPS.FINAL}
                >
                    <FinalStep onFinished={reset} claimId={claim.claimId} hostTime={claim.hostTime}/>
                </ClaimCard>
                }
            </div>
        </div>

        <div className="modal fade" id="cancelModal" tabIndex="-1" aria-labelledby="cancelModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="cancelModalLabel">{t('claim.cancelModal.title')}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {t('claim.cancelModal.body')}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('claim.cancelModal.close')}</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={reset}>{t('claim.cancelModal.confirm')}</button>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="helpModal" tabIndex="-1" aria-labelledby="helpModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="helpModalLabel">{t('claim.product.where')}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <img className="w-100" src="/img/5x5.png" alt="location of 5x5 barcode" aria-label={t('claim.product.tooltip')} tabIndex="0" />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-dismiss="modal">{t('claim.cancelModal.close')}</button>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
