import { formatISO, parseISO, sub } from 'date-fns';
import { createServer } from 'miragejs';

let options;
let translations;

export function makeServer({ environment = 'test' } = {}) {

    const server = createServer({
        environment,

        routes() {

            // IE 11 is grabbing the /locales calls
            //this.passthrough('/locales/translation/:lng');
            this.passthrough();

            this.namespace = '/api/v2/ecf';
            //this.timing = 2000;

            this.post('/validate-product-key', (_, request) => {

                const { productKey } = JSON.parse(request.requestBody);
                switch (productKey) {

                    case '11111-11111-11111-11111-11111':
                        return { success: false, error: 1 };
                    case '22222-22222-22222-22222-22222':
                        return { success: false, error: 2 };
                    case '33333-33333-33333-33333-33333':
                        return { success: false, error: 3 };
                    case '4444-44444-44444-44444-44444':
                        return { success: false, error: 4 }
                    case '55555-55555-55555-55555-55555':
                        return { success: false, error: 5 }
                    case '66666-66666-66666-66666-66666':
                        return { success: false, error: 6 }
                    case '77777-77777-77777-77777-77777':
                        return { success: false, error: 7 }
                    case '88888-88888-88888-88888-88888':
                        return { success: true, data: { claimId: 3008, subscription: 2 } }
                    default:
                        return { success: true, data: { claimId: 3004, subscription: null } }
                };
            });

            this.post('/validate-email', (_, request) => {

                const { email } = JSON.parse(request.requestBody);
                switch (email) {
                    case 'joe@here.com':
                        return { success: false, error: 11 };
                    default:
                        return { success: true };
                }

            });

            this.get('/options/:promo/:lng', async () => {

                if (!options) {

                    const o = await import('./server/options.json');
                    options = o.default;
                }
                return {
                    success: true,
                    data: options
                };
            });

            this.get('/locales/translation/:promo/:lng', async (_, request) => {

                if (!translations) {

                    const t = await import('./server/translation');
                    translations = t.default;
                }
                return translations[request.params.lng] || translations.en
            });

            this.post('/transaction', (_, request) => {

                const { receiptDate } = JSON.parse(request.requestBody);

                if (parseISO(receiptDate) < sub(new Date(), { days: 30 })) {
                    return { success: false, error: 18 };
                }

                return { success: true };
            });

            this.post('/purchase-detail', (_, request) => {

                return { success: true };
            });

            this.post('/upload', (_, request) => {
                return {success: true, data: { hostTime: formatISO(new Date()) }};
            });
        },
    });

    return server;
}


