import { useEffect, useRef } from 'react';
import isObject from 'lodash/isObject';
import { useFormikContext } from 'formik';

const getFirstErrorKey = (object, keys = []) => {
    const firstErrorKey = Object.keys(object)[0];
    if (isObject(object[firstErrorKey])) {
        return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
    }
    return [...keys, firstErrorKey].join('.');
};

export const FocusOnFirstError = () => {

    const formik = useFormikContext();
    const submitCountRef = useRef(formik.submitCount);

    useEffect(() => {

        if (!formik.isValid && formik.submitCount > submitCountRef.current) {
            const firstErrorKey = getFirstErrorKey(formik.errors);
            if (global.window.document.getElementsByName(firstErrorKey).length) {
                global.window.document.getElementsByName(firstErrorKey)[0].focus();
            }
            submitCountRef.current = formik.submitCount;
        }
    }, [formik.submitCount, formik.isValid, formik.errors]);

    return null;
};