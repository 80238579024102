import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useHistory } from 'react-router';

import { localesList } from '../../locales';
import { Helmet } from 'react-helmet-async';

export default () => {

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const changeLanguage = useCallback(async (e, code) => {

        e.preventDefault();
        await i18n.changeLanguage(code);
        history.push('/');
    }, [i18n, history]);

    return <div className="container-fluid mb-5 locales-page" role="main">
        <Helmet>
            <title>{t('locales.title')}</title>
        </Helmet>
        <div className="row mb-2">
            <div className="col">
                <h1 className="font-weight-lighter">{t('locales.heading')}</h1>
            </div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3" role="list">
            {localesList.map(l => <div key={l.code} className="col" role="listitem">
                {// eslint-disable-next-line
                    <a
                        href="#"
                        lang={l.code}
                        className={classnames('btn btn-link', { active: l.code === i18n.language })}
                        onClick={(e) => changeLanguage(e, l.code)}>
                        {l.display}
                    </a>}
            </div>
            )}
        </div>

    </div>
};