import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHtmlTranslation } from '../../locales';
import AnchorClickBlock from '../shared/AnchorClickBlock';
import LoadingSpinner from '../shared/LoadingSpinner';

export default () => {

    const { t } = useTranslation();
    const howHtml = useHtmlTranslation('how');

    return <div className="container-fluid" role="main">
        <Helmet>
            <title>{t('how.title')}</title>
        </Helmet>
        <div className="row">
            <div className="col">
                <h1>{t('how.title')}</h1>
            </div>
        </div>

        {!howHtml && <LoadingSpinner />}

        {howHtml && howHtml.__html && <AnchorClickBlock html={howHtml.__html} />}
    </div>;
}