import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHtmlTranslation } from '../../locales';
import LoadingSpinner from '../shared/LoadingSpinner';

export const TermsPanel = () => {

    const termsHtml = useHtmlTranslation('terms');
    return <>
        {!termsHtml && <LoadingSpinner />}

        {termsHtml && termsHtml.__html && <div dangerouslySetInnerHTML={{ __html: termsHtml.__html }}></div>}
    </>;
}

export default () => {

    const { t } = useTranslation();
    return <div className="container-fluid" role="main">
        <Helmet>
            <title>{t('terms.title')}</title>
        </Helmet>
        <h1 className="text-center">{t('terms.heading')}</h1>
        <h3 className="text-center">{t('claim.terms.heading')}</h3>
        <TermsPanel />
    </div>
}