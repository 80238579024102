import { useEffect, useState } from 'react';
import {
    enUS, enGB, af, arSA, bg, cs, da, nl, fi, fr, de, el, he, hi, hu, is, id, it,
    ja, kk, ko, lv, lt, ms, pl, ptBR, pt, ro, ru, zhCN, sk, sl, es, sv, th, zhTW, tr, et, hr, uk //,vi
} from 'date-fns/locale';

import { registerLocale } from 'react-datepicker';
import { rootHandler } from './ajax/handler';
import { useTranslation } from 'react-i18next';
import { TIMESTAMP } from './constants';

// VS Code extension - https://marketplace.visualstudio.com/items?itemName=JeffJorczak.auto-translate-json
export const localesList = [
    { code: 'en', iso: 'English (US)', native: 'English', dateFns: enUS, country: 'United States' },
    { code: 'en-GB', iso: 'English (UK)', native: 'English', dateFns: enGB, country: 'United Kingdom' },
    { code: 'af', iso: 'Afrikaans', native: 'Afrikaans', dateFns: af },
    { code: 'ar', iso: 'Arabic', native: 'العربية', dateFns: arSA, country: 'المملكة العربية السعودية', dir: 'rtl' },
    { code: 'bg', iso: 'Bulgarian', native: 'Български', dateFns: bg, country: 'България' },
    { code: 'zh-CN', iso: 'Chinese (simplified)', native: '简体中文', dateFns: zhCN, country: '中国' },
    { code: 'zh-TW', iso: 'Chinese (traditional)', native: '繁體中文', dateFns: zhTW, country: '台灣' },
    { code: 'cs', iso: 'Czech', native: 'Čeština', dateFns: cs, country: 'Česká Republika' },
    { code: 'da', iso: 'Danish', native: 'Dansk', dateFns: da, country: 'Danmark' },
    { code: 'nl', iso: 'Dutch', native: 'Nederlands', dateFns: nl, country: 'Nederland' },
    { code: 'tl', iso: 'Filipino', native: 'Wikang Tagalog', dateFns: enUS },
    { code: 'fi', iso: 'Finnish', native: 'Suomi', dateFns: fi },
    { code: 'fr', iso: 'French', native: 'Français', dateFns: fr, country: 'France' },
    { code: 'de', iso: 'German', native: 'Deutsch', dateFns: de, country: 'Deutschland' },
    { code: 'el', iso: 'Greek', native: 'ελληνικά', dateFns: el, country: 'Ελλάδα' },
    { code: 'he', iso: 'Hebrew', native: 'עברית', dateFns: he, country: 'ישראל', dir: 'rtl' },
    { code: 'hi', iso: 'Hindi', native: 'हिन्दी, हिंदी', dateFns: hi }, // ms uses en-IN
    { code: 'hu', iso: 'Hungarian', native: 'Magyar', dateFns: hu, country: 'Magyarország' },
    { code: 'is', iso: 'Icelandic', native: 'Íslenska', dateFns: is, country: 'Ísland' },
    { code: 'id', iso: 'Indonesian', native: 'Bahasa Indonesia', dateFns: id, country: 'Indonesia' },
    { code: 'it', iso: 'Italian', native: 'Italiano', dateFns: it, country: 'Italia' },
    { code: 'ja', iso: 'Japanese', native: '日本語', dateFns: ja, country: '日本' },
    { code: 'kk', iso: 'Kazakh', native: 'қазақ тілі', dateFns: kk },
    { code: 'ko', iso: 'Korean', native: '한국어', dateFns: ko, country: '대한민국' },
    { code: 'lv', iso: 'Latvian', native: 'Latviešu', dateFns: lv, country: 'Latvija' },
    { code: 'lt', iso: 'Lithuanian', native: 'Lietuvių', dateFns: lt, country: 'Lietuva' },
    { code: 'ms', iso: 'Malay', native: 'Bahasa Melayu', dateFns: ms },
    { code: 'no', iso: 'Norway', native: 'Norsk', dateFns: is },
    { code: 'pl', iso: 'Polish', native: 'Polski', dateFns: pl, country: 'Polska' },
    { code: 'pt-BR', iso: 'Portuguese (Brazilian)', native: 'Português', dateFns: ptBR, country: 'Brasil' },
    { code: 'pt', iso: 'Portuguese (European)', native: 'Português', dateFns: pt, country: 'Portugal' },
    { code: 'ro', iso: 'Romanian', native: 'Română', dateFns: ro, country: 'România' },
    { code: 'ru', iso: 'Russian', native: 'Русский', dateFns: ru, country: 'Россия' },
    { code: 'sk', iso: 'Slovak', native: 'Slovenčina', dateFns: sk, country: 'Slovensko' },
    { code: 'sl', iso: 'Slovenian', native: 'Slovenščina', dateFns: sl, country: 'Slovenija' },
    { code: 'es', iso: 'Spanish', native: 'Español', dateFns: es },
    { code: 'sv', iso: 'Swedish', native: 'Svenska', dateFns: sv, country: 'Sverige' },
    { code: 'th', iso: 'Thai', native: 'ไทย', dateFns: th, country: 'ไทย' },
    { code: 'tr', iso: 'Turkish', native: 'Türkçe', dateFns: tr, country: 'Türkiye' },
    { code: 'ur', iso: 'Urdu', native: 'اردو', dateFns: hi },
    { code: 'et', iso: 'Estonia', native: 'eesti (Eesti)', dateFns: et },
    { code: 'uk', iso: 'Ukrainian', native: 'українська (Україна)', dateFns: uk },
    { code: 'hr', iso: 'Croatian', native: 'hrvatski (Hrvatska)', dateFns: hr },
    { code: 'en-ZA', iso: 'South Africa English', native: 'South Africa English', dateFns: enUS },
    { code: 'en-HK', iso: 'English (Hong Kong SAR)', native: 'English (Hong Kong SAR)', dateFns: enUS },
    { code: 'zh-HK', iso: 'Hong Kong - Chinese Traditional', native: '繁體中文', dateFns: zhTW, country: '香港' },
    // { code: 'sr', iso: 'Serbian (Latin, Serbia)', native: 'srpski (Srbija)', dateFns: sr },
    //{ code: 'vi', iso: 'Vietnamese', native: 'Tiếng việt', dateFns: vi, country: 'Việt Nam' },
];

const locales = {};
for (const item of localesList) {

    item.display = item.iso === item.native ? item.iso : `${item.country || item.iso} - ${item.native}`;
    item.headerDisplay = item.iso === item.native ? item.iso : `${item.native} (${item.country || item.iso})`;

    locales[item.code] = item;
    registerLocale(item.code, item.dateFns);
}
localesList.sort((a, b) => a.display.localeCompare(b.display));

export const defaultLng = 'en';
export const defaultLocale = locales[defaultLng];

export const getLocaleOrDefault = (lng) => locales[lng] || defaultLocale;

export default locales;


let cachedHtml = {};
let cachedHtmlLanguage = {};

// returns null while loading, {} if not found, and {__html: ''} if found
export const useHtmlTranslation = (path) => {

    const { i18n } = useTranslation();
    const [html, setHtml] = useState(i18n.language === cachedHtmlLanguage[path] && cachedHtml[path] ? cachedHtml[path] : null);

    useEffect(() => {

        const load = async () => {

            let result = {};
            cachedHtmlLanguage[path] = null;
            cachedHtml[path] = null;

            try {

                const response = await rootHandler.get(`/locales/${path}/${i18n.language}.html?t=${TIMESTAMP}`);
                result = {
                    __html: response
                };
            } catch {

                const response = await rootHandler.get(`/locales/${path}/en.html?t=${TIMESTAMP}`);
                result = {
                    __html: response
                };
            }

            cachedHtmlLanguage[path] = i18n.language;
            cachedHtml[path] = result;
            setHtml(result);
        }

        if (!html) {
            load();
        }
    }, [html, i18n.language, path]);

    return html;
}