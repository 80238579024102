export const supportEmail = 'customersupport@epayworldwide.com';

export const msPrivacyUrl = "https://go.microsoft.com/fwlink/?LinkId=521839";

export const epayPrivacyUrl = "https://www.epayworldwide.com/privacy-policy/";

export const promo = 1;

export const API_URL = '/api/v2/ecf';

export const TIMESTAMP = new Date().getTime();

export const APP_VERSION = process.env.REACT_APP_VERSION || TIMESTAMP;

export const chPrivacyUrl = "https://go.microsoft.com/fwlink/?linkid=2259814";