import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DropZone from 'react-dropzone';
import { isIE, isMobileSafari, isSafari } from 'react-device-detect';
import { DatePickerField, FieldGroup, SelectField, TextField } from '../shared/FormElements';
import { formatISO } from 'date-fns';
import { getLocaleOrDefault } from '../../locales';
import classnames from 'classnames';
import { ErrorMessage } from 'formik';
import md5 from 'md5';

const pdfjsLib = window['pdfjs-dist/build/pdf'];
pdfjsLib.GlobalWorkerOptions.workerSrc = '/vendor/js/pdf-2.6.347-es5.worker.js';

const PreviewPdf = ({ file, setError, desiredWidth }) => {

	const { t } = useTranslation();
	const canvasRef = useRef();

	useEffect(() => {

		const reader = new FileReader();
		reader.onerror = () => setError(t([`errors.fileRead`, 'errors.unspecified']));
		reader.onloadend = () => {

			const data = new Uint8Array(reader.result);
			pdfjsLib.getDocument({ data }).promise
				.then(pdf => {

					pdf.getPage(1).then(page => {

						const baseViewport = page.getViewport({ scale: 1 });

						const scale = (desiredWidth || 500) / baseViewport.width;
						const viewport = page.getViewport({ scale });
						var canvas = canvasRef.current;
						var canvasContext = canvas.getContext('2d');
						canvasContext.height = viewport.height;
						canvasContext.width = viewport.width;

						canvas.height = viewport.height;
						canvas.width = viewport.width;

						page.render({ canvasContext, viewport }).promise
							.catch(() => setError(t([`errors.fileRead`, 'errors.unspecified'])));
					});
				})
				.catch(() => setError(t([`errors.fileRead`, 'errors.unspecified'])));;
		}
		reader.readAsArrayBuffer(file);
	}, [file, setError, t, desiredWidth]);

	return <canvas ref={canvasRef} />
}

const PreviewImage = ({ file, setError }) => {

	const { t } = useTranslation();
	const [imageSrc, setImageSrc] = useState(null);
	useEffect(() => {

		const reader = new FileReader()

		//reader.onabort = () => console.log('file reading was aborted')
		reader.onerror = () => setError(t([`errors.fileRead`, 'errors.unspecified']));
		reader.onloadend = () => {

			setImageSrc(reader.result);
		}
		reader.readAsDataURL(file);
	}, [file, setError, t]);

	return <>{imageSrc && <img src={imageSrc} alt="Receipt preview" style={{ maxWidth: '100%' }} />}</>;
}

const DropzoneError = props => {
	return <div className="text-danger pb-2">{props.children}</div>;
};

export default (props) => {

	const { t, i18n } = useTranslation();

	const datePlaceholder = useMemo(() => getLocaleOrDefault(i18n.language).dateFns.formatLong.date({ width: 'short' }).toLowerCase(), [i18n.language]);

	const [dropError, setDropError] = useState();
	const [error, setError] = useState();
	const containerRef = useRef();

	const proofTypeFilter = (element, index) => {
		if(props.values.pop.length > 1) {
			if(props.id > 0) {
				return element.allowMultiple;// && props.values.pop[0].type !== element.value;
			} else {
				return element.allowMultiple;
			}
		} else {
			return true;
		}
	};

	const reset = useCallback(() => {

		setDropError(null);
		setError(null);

	}, []);

	const readAsArrayBuffer = (file) => {
		return new Promise(function(resolve) {
			var reader = new FileReader();
			reader.readAsArrayBuffer(file)
			reader.onload = function(e) {
				resolve(e.target.result)
			};
		});
	}

	return <>
		<div className="row mt-3">
			<div className="col-12 col-md-6">
				<div className='row'>
					<div className="col-12 col-md-8">
						<FieldGroup
							name={ "pop."+ props.id + ".type"}
							label={t('expansion.POPTypeDropdown.label') + ' *'}
							component={SelectField}
							className={classnames('custom-select-lg','custom-disabled')}
							aria-required
							disabled
						>
							<option value="" disabled hidden>{t('site.select')}</option>
							{props.options.proofTypes.filter(proofTypeFilter).map((o, i) => <option key={i} value={o.value}>{o.label}</option>)}
						</FieldGroup>
					</div>
					<div className="col-12 col-md-4">
						{(isIE || (isSafari && !isMobileSafari))
							?
							<FieldGroup
								name={ "pop."+ props.id +".purchaseDate"}
								label={t('claim.detail.receiptDate') + ' *'}
								component={DatePickerField}
								className="form-control-lg"
								wrapperClassName="d-block"
								locale={i18n.language}
								placeholder={datePlaceholder}
								maxDate={new Date()}
								disabled={props.disabled}
							/>
							:
							<FieldGroup
								name={ "pop."+ props.id +".purchaseDate"}
								label={t('claim.detail.receiptDate') + ' *'}
								component={TextField}
								type="date"
								className="form-control-lg"
								max={formatISO(new Date(), { representation: 'date' })}
								aria-required
								disabled={props.disabled}
							/>}
					</div>
				</div>

				{	props.values && props.values.pop.some(p => p.type && p.type.length > 0) &&
					props.options.proofTypes.find(pt => pt.value === props.values.pop[props.id].type && pt.description.length > 0) &&
				<div className="row no-gutters">
					<div className="col-12">
						<div className="alert alert-warning" id={ "proof-type-"+props.id+"-description" } role="alert">
							{props.options.proofTypes.find(pt => pt.value === props.values.pop[props.id].type).description}
						</div>
					</div>
				</div>}

				<DropZone
					name={ "pop."+ props.id +".file"}
					multiple={false}
					accept="application/pdf,image/jpeg,image/png,image/gif"
					maxSize={4 * 1024 * 1024}
					disabled={props.disabled}
					onDrop={async acceptedFiles => {

						reset();

						if (acceptedFiles.length === 0) {
							setDropError(t('claim.proof.dropError'));
							return;
						}

						readAsArrayBuffer(acceptedFiles[0])
							.then(arrayBuffer => {
								const buffer = Buffer.from(arrayBuffer);
								const fileHash = md5(buffer);

								if (props.values && props.values.pop && props.values.pop.filter(i => i.hash === fileHash).length > 0) {
									setDropError(t('claim.proof.dropSameFile'));
									return;
								}
								props.setFile(acceptedFiles[0], fileHash);
							});
					}}>
					{({ getRootProps, getInputProps }) => (
						<div {...getRootProps()} className="dropzone text-center" role="button" aria-labelledby="dragInstructionsReader">
                            <span id="dragInstructionsReader" className="d-none">
                                {t('claim.proof.dragInstructions')} {t('validation.required')}
                            </span>
							<p id={ "dragInstructions-"+props.id} className="p-3">
								{t('claim.proof.dragInstructions')}
							</p>
							<input {...getInputProps()} />
						</div>
					)}
				</DropZone>
				<ErrorMessage name={`pop.${props.id}.file`} component={DropzoneError}/>

				{dropError && <div className="row mt-3 mx-auto">
					<div className="col">
						<div className="alert alert-danger" id="proof-drop-error" role="alert">
							{dropError}
						</div>
					</div>
				</div>}
			</div>

			<div className="col-12 col-md-6 border-md-left" ref={containerRef}>
				{props.file && <>
					<div className="row mb-3 mt-3 mt-md-0">
						<div className="col text-center">
							<p className="font-weight-bold text-uppercase">{props.file.name}</p>
							{props.file.type === 'application/pdf' && <PreviewPdf file={props.file} setError={setError} desiredWidth={containerRef.current.clientWidth - 50} />}
							{props.file.type !== 'application/pdf' && <PreviewImage file={props.file} setError={setError} />}
						</div>
					</div>
					{error && <div className="row no-gutters">
						<div className="col">
							<div className="alert alert-danger" id="proof-step-error" role="alert">
								{error}
							</div>
						</div>
					</div>}
				</>}
			</div>
		</div>
	</>
};
