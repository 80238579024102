import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useRouteMatch } from 'react-router-dom';

import logo from '../img/ms-logo.png';
import { getLocaleOrDefault } from '../locales';

const closeNav = () => window.jQuery('#navbarSupportedContent').collapse('hide');

const onNavKeyDown = (e) => {

    if (e.key === 'Escape') {
        closeNav();
    } else if (e.key === "ArrowDown") {

        const focused = document.querySelector('.navbar .nav-link:focus');
        const all = document.querySelectorAll('.navbar .nav-link');
        let found = -1;
        for (let index = 0; index < all.length; index++) {
            if (focused === all[index]) {
                found = index;
            }

        }
        if (all[found + 1]) {
            all[found + 1].focus();
            e.preventDefault();
        }
    } else if (e.key === "ArrowUp") {

        const focused = document.querySelector('.navbar .nav-link:focus');
        const all = document.querySelectorAll('.navbar .nav-link');

        let found = all.length;
        for (let index = 0; index < all.length; index++) {
            if (focused === all[index]) {
                found = index;
            }

        }
        if (all[found - 1]) {
            all[found - 1].focus();
        } else {
            document.querySelector('.navbar .navbar-toggler').focus();
        }
        e.preventDefault();
    }
};

export default () => {

    const { t, i18n } = useTranslation();
    const locale = getLocaleOrDefault(i18n.language);

    const isClaimPage = useRouteMatch({ path: '/', exact: true });

    return <header className="mb-1">
        <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top" onKeyDown={onNavKeyDown}>
            <a href="/" className="logo-link mr-2 pr-3" onClick={closeNav} aria-current={(isClaimPage ? 'page' : '')}>
                <img className="logo" src={logo} alt={t('site.header.logo.alt')} />
            </a>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                        <NavLink to="/" className="nav-link" exact aria-current="page" onClick={closeNav}>{t('claim.title')}</NavLink>
                    </li>
                    {/* <li className="nav-item">
                        <NavLink to="/eligible-retailers" className="nav-link" aria-current="page" onClick={closeNav}>{t('eligible.title')}</NavLink>
                    </li> */}
                    <li className="nav-item">
                        <NavLink to="/how-it-works" className="nav-link" aria-current="page" onClick={closeNav}>{t('how.title')}</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/support" className="nav-link" aria-current="page" onClick={closeNav}>{t('support.title')}</NavLink>
                    </li>
                </ul>

                <ul className="navbar-nav mr-2 mt-2 mt-lg-0">
                    <li className="nav-item">
                        <i className="fas fa-globe-americas mr-1 mr-lg-0"></i>
                        <NavLink to="/locales" className="nav-link d-inline" aria-current="page" onClick={closeNav}>{locale.headerDisplay}</NavLink>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
};
