import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { format } from 'date-fns';
import { setLocale } from 'yup';

import locales, { defaultLng, getLocaleOrDefault } from './locales';
import { API_URL, promo, TIMESTAMP } from './constants';

i18n
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(process.env.REACT_APP_STATIC === 'true' ? XHR : Backend) // for dev, using XHR so miragejs handles the call
    .use(LanguageDetector)
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        //debug: process.env.NODE_ENV === 'development',

        //lng: defaultLng, //setting this overrides default LanguageDetector
        fallbackLng: defaultLng,
        //load: 'languageOnly',
        //nonExplicitSupportedLngs: true,
        supportedLngs: Object.keys(locales),

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            //loadPath: '/locales/{{ns}}/{{lng}}.json', // swapping "normal" ns and lng so the auto-translate works better
            loadPath: `${API_URL}/locales/{{ns}}/${promo}/{{lng}}`,
            queryStringParams: { t: TIMESTAMP },
            //parse: r => JSON.parse(r).data
        },

        detection: {
            order: ['queryString', 'sessionStorage', 'navigator', 'htmlTag'],
            caches: ['sessionStorage']
        }
    });

// passing the locale through properties to force display change as the language changes
// i18n.on('languageChanged', lng => {

//     setDefaultLocale(locales[lng] ? lng : defaultLng);
// })

// for options/structure, see https://github.com/jquense/yup/blob/master/src/locale.js
// this works, but does not change the messages for already loaded error messages, https://github.com/jquense/yup/issues/758
setLocale({
    mixed: {
        required: () => i18n.t('validation.required')
    },
    string: {
        max: ({ max }) => { console.log(max); return i18n.t('validation.max', { max }) },
        matches: () => i18n.t('validation.matches'),
        email: () => i18n.t('validation.email'),
        length: ({ length }) => i18n.t('validation.length', { length }),
        date: () => i18n.t('validation.date'),
    }
});

export const formatDate = (date, formatStr = 'P') => format(date, formatStr, { locale: getLocaleOrDefault(i18n.language).dateFns });

export default i18n;