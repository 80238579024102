import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { FieldGroup, SelectField, TextField, SelectTypeahead } from '../shared/FormElements';
import { savePurchaseDetail, uploadReceipt } from '../../ajax/api';
import { useTranslation } from 'react-i18next';
import { FocusOnFirstError } from '../../utils';
import DenotesRequiredRow from '../shared/DenotesRequiredRow';
import classnames from 'classnames';

import LoadingSpinner from '../shared/LoadingSpinner';
import ProofOfPurchaseInput from './ProofOfPurchaseInput';

const InnerForm = ({ values, options, setStatus, setFieldValue, isSubmitting, error, hideSubscription, disabled, setValues, errors }) => {

	const { t } = useTranslation();

	const merchantRef = useRef();

	const merchants = useMemo(() => {

		if (!options) return [];

		return values.country
			? options.merchants.filter(m => m.country === values.country)
			: options.merchants;
	}, [options, values.country]);

	const typeaheadAllowNew = (results, props) => {
		return (results && results.length === 0 && props.text && props.text.trim().length >= 2);
	};

	const typeaheadOnChange = (selected) => {
		if(selected && selected.length > 0){
			const item = selected[0];
			if (item.customOption) {
				setFieldValue('merchant', "not-listed|" + item.label.trim());
			} else {
				setFieldValue('merchant', item.value);
			}
		}
	};

	const addFile = (file, hash, index, values, setValues) => {
		const tmpPop = [...values.pop];
		tmpPop[index].file = file;
		tmpPop[index].hash = hash;
		setValues({ ...values, pop: tmpPop});
	};

	useEffect(() => {
		if (options && values.merchant && !values.merchant.startsWith('not-listed|') && !merchants.find(m => m.value === values.merchant)) {
			setFieldValue('merchant', '');
			merchantRef.current.clear();
		}
	}, [options, merchants, values.merchant, setFieldValue]);

	useEffect(() => {
		const newPOPVal = [{
			type: '',
			purchaseDate: '',
			file: null,
			hash: ''
		}];

		if (values.moreThanOnePOP === 'Y') {
			newPOPVal.pop();
			newPOPVal.push({
				type: 'QP',
				purchaseDate: '',
				file: null,
				hash: ''
			});
			newPOPVal.push({
				type: 'QD',
				purchaseDate: '',
				file: null,
				hash: ''
			});
		} else if (values.moreThanOnePOP === 'N') {
			newPOPVal.pop();
			newPOPVal.push({
				type: 'BT',
				purchaseDate: '',
				file: null,
				hash: ''
			});
		}
		setFieldValue('pop', newPOPVal);
	},[options, values.moreThanOnePOP, setFieldValue]);

	if (!options) return <LoadingSpinner />;

	return <Form noValidate autoComplete="off">
		<FocusOnFirstError />
		<div className="row">
			<div className="col-12">
				<FieldGroup
					name="email"
					className="form-control-lg"
					label={t('claim.email.email.label') + ' *'}
					placeholder={t('claim.email.email.placeholder')}
					autoFocus
					component={TextField}
					errorName={t('claim.email.email.placeholder')}
					aria-required
					disabled={disabled}
				/>
			</div>
		</div>

		<div className='row'>
			<div className="col-12">
				<FieldGroup
					name="confirmEmail"
					className="form-control-lg"
					label={t('claim.email.confirmEmail.label') + ' *'}
					placeholder={t('claim.email.confirmEmail.placeholder')}
					component={TextField}
					aria-required
					disabled={disabled}
				/>
			</div>
		</div>

		<div className="row">
			<div className="col-12 col-md-6">
				<FieldGroup
					name="country"
					label={t('claim.detail.country') + ' *'}
					component={SelectField}
					className={classnames('custom-select-lg', { empty: !values.country })}
					aria-required
					disabled={disabled}
				>
					<option value="" disabled hidden>{t('site.select')}</option>
					{options.countries.map((o, i) => <option key={i} value={o.value}>{o.label}</option>)}
				</FieldGroup>
			</div>
			<div className="col-12 col-md-6">
				<FieldGroup
					name="merchant"
					label={t('claim.detail.retailer') + ' *'}
					placeholder={t('expansion.retailerPlaceholderInput')}
					component={SelectTypeahead}
					aria-required
					items={merchants}
					allowNew={typeaheadAllowNew}
					onChange={typeaheadOnChange}
					disabled={disabled}
					forwardedRef={merchantRef}
					emptyLabel={t('expansion.retailerPlaceholderInput')}
				>
				</FieldGroup>
			</div>
		</div>
		<div className="row">
			<div className="col-12 col-md-6">
				<FieldGroup
					name="receiptLanguage"
					label={<>{t('claim.detail.receiptLanguage')} <em>{t('claim.detail.receiptLanguageOther')}</em> *</>}
					errorName={t('claim.detail.receiptLanguage')}
					component={SelectField}
					className={classnames('custom-select-lg', { empty: !values.receiptLanguage })}
					aria-required
					disabled={disabled}
				>
					<option value="" disabled hidden>{t('site.select')}</option>
					{options.receiptLanguages.map((o, i) => <option key={i} value={o.value}>{o.label}</option>)}
				</FieldGroup>
			</div>
			<div className="col-12 col-md-6">
				<FieldGroup
					name="qualifyingPurchase"
					label={t('claim.detail.qualifyingPurchase') + ' *'}
					component={SelectField}
					className={classnames('custom-select-lg', { empty: !values.qualifyingPurchase })}
					aria-required
					disabled={disabled}
				>
					<option value="" disabled hidden>{t('site.select')}</option>
					{options.devices.map((o, i) => <option key={i} value={o.value}>{o.label}</option>)}
				</FieldGroup>
			</div>
		</div>
		{!hideSubscription &&
		<div className="row">
			<div className="col-12 col-md-6">
				<FieldGroup
					name="subscription"
					label={t('claim.detail.subscription') + ' *'}
					component={SelectField}
					className={classnames('custom-select-lg', { empty: !values.subscription })}
					aria-required
					disabled={disabled}
				>
					<option value="" disabled hidden>{t('site.select')}</option>
					{options.subscriptions.map((o, i) => <option key={i} value={o.value}>{o.label}</option>)}
				</FieldGroup>
			</div>
		</div>}

		<h4 className="font-weight-normal">{t('claim.proof.heading')}</h4>

		<div className="row">
			<div className='col'>
				<div className="alert alert-primary" dangerouslySetInnerHTML={{
					__html: t('claim.proof.lastStep')
				}}>
				</div>
			</div>
		</div>

		<div className='row'>
			<div className="col-12 col-md-6">
				<FieldGroup
					name="moreThanOnePOP"
					label={t('expansion.moreThanOnePOP.label') + ' *'}
					component={SelectField}
					className={classnames('custom-select-lg', { empty: !values.moreThanOnePOP })}
					aria-required
					disabled={disabled}
				>
					<option value="" disabled hidden>{t('site.select')}</option>
					{options.moreThanOnePOP.map((o, i) => <option key={i} value={o.value}>{o.label}</option>)}
				</FieldGroup>
			</div>
		</div>

		{values.pop.map((pop,i) => { return (
				<ProofOfPurchaseInput
					key={i}
					id={i}
					type={pop.type}
					purchaseDate={pop.purchaseDate}
					file={pop.file}
					disabled={disabled}
					setFile={(file, hash) => addFile(file, hash, i, values, setValues)}
					options={options}
					values={values}
				/>);
		})}

		{error && <div className="row no-gutters">
			<div className="col-12">
				<div className="alert alert-danger" id="detail-step-error" role="alert">
					{error}
				</div>
			</div>
		</div>}

		{!disabled && <>
			<div className="row">
				<div className="col-12 text-right mt-1">
					<button
						type="button"
						className="btn btn-danger mr-2"
						disabled={isSubmitting}
						data-toggle="modal"
						data-target="#cancelModal">{t('site.cancel')}</button>
					<button
						type="submit"
						className="btn btn-primary"
						disabled={isSubmitting}
						onClick={() => setStatus('submitting')}>{t('site.submitClaim')}</button>
				</div>
			</div>
		</>}
		<DenotesRequiredRow />
	</Form>
};

export default ({ options, onSuccess, claim, hideSubscription, disabled }) => {

	const [error, setError] = useState();
	const { t } = useTranslation();
	const today = new Date();
	const pop = [{
		type: '',
		purchaseDate: '',
		file: null
	}];

	const merchantBlacklistVal = (value, country) => {
		if (value && options.blacklistMerchants) {
			const cleanValue = value.replace('not-listed|','').replace(/[^\w\s]/gi, '').toLowerCase();
			return options.blacklistMerchants.filter(bm => {
				return bm.label.replace(/[^\w\s]/gi, '').toLowerCase() === cleanValue && bm.country === country;
			}).length === 0;
		} else {
			return false;
		}
	};

	return <>
		<Formik
			enableReinitialize
			disabled={disabled}
			initialValues={{
				email: '',
				confirmEmail: '',
				country: '',
				merchant: '',
				receiptLanguage: '',
				subscription: claim.subscription ?? '',
				qualifyingPurchase: '',
				moreThanOnePOP: '',
				pop: pop
			}}
			validationSchema={Yup.object().shape({
				email: Yup.string().trim().required().max(256).email(),
				confirmEmail: Yup.string().required().oneOf([Yup.ref('email')], () => t('validation.confirmEmail')),
				country: Yup.string().required(),
				merchant: Yup.string().required().when('country', (country, field) =>
					(country && options.blacklistMerchants) ?
					field.test('merchant-blacklist', t('expansion.retailerBlacklistValidation'), (value) =>  merchantBlacklistVal(value, country)) :
					field
				),
				receiptLanguage: Yup.string().required(),
				subscription: Yup.string().required(),
				qualifyingPurchase: Yup.string().required(),
				moreThanOnePOP: Yup.string().required(),
				pop: Yup.array().of(
					Yup.object().shape({
						type: Yup.string().required(),
						purchaseDate: Yup.date().required().max(today, t('validation.date')),
						file: Yup.mixed().required()
					})
				)
			})}
			onSubmit={ async (values, { setSubmitting }) => {
				try {

					const { email, country, merchant, receiptLanguage, subscription, qualifyingPurchase } = values;
					const result = await savePurchaseDetail({
						claimId: claim.claimId, email, country, merchant, receiptLanguage, subscription: subscription.toString(), qualifyingPurchase
					});

					if (result.success) {
						const formData = new FormData();
						formData.append('claimId', claim.claimId);
						values.pop.forEach(f => {
							formData.append('file'+f.type, f.file);
							formData.append('purchaseDate'+f.type, f.purchaseDate);
						});

						const uploadResult = await uploadReceipt(formData);
						setSubmitting(false);
						if (uploadResult.success) {
							onSuccess({ ...uploadResult.data });
							setError();
						} else {
							setError(t([`errors.${uploadResult.error}`, 'errors.unspecified']));
						}
					} else {
						setError(t([`errors.${result.error}`, 'errors.unspecified']));
					}
				} catch {
					setSubmitting(false);
					setError(t([`errors.500`, 'errors.unspecified']));
				} finally {
				}
			}}
		>
			{(formProps) => <InnerForm {...formProps} options={options} error={error} hideSubscription={hideSubscription} disabled={disabled} />}
		</Formik>
	</>;
}
